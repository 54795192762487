.chat {
    /* 일반 채팅 스타일 */
    text-align: left;
    border: 1px solid #ccc;
  }
  
  .my-chat {
    /* 사용자의 채팅 스타일 (오른쪽으로 정렬) */
    text-align: right;
  }
  
  .info-message {
    /* JOIN 및 LEAVE 메시지를 가운데 정렬합니다. */
    text-align: center;
    font-weight: bold; /* 필요에 따라 글꼴 굵기를 설정하세요. */
    color: #555; /* 필요에 따라 글꼴 색상을 설정하세요. */
    background-color: #f2f2f2; /* 예시로 배경색을 지정 */
  }
  