/* 메인 박스 설정 */
.box {
  display: flex;
  width: 100%;
  height: 85vh;
}

/* 좌측 컨테이너 설정 */
.left-container {
  flex: 70%;
  background-color: #ffffffc0;
  height: 100%;
  overflow-y: auto;
}

/* 우측 컨테이너 설정 */
.right-container {
  flex: 30%;
  background-color: #ffffff;
  height: 100%;
}

/* 비디오 박스 설정 */
.video-box {
  width: 50%;
  height: 50%;
  float: left;
  background-color: rgb(238, 215, 207);
}

/* 비디오 컴포넌트 설정 */
.video-component {
  object-fit: cover;
  width: 100%;
  height: 95%;
  float: left;
  display: block;
}

/* 채팅 컨테이너 설정 */
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-wrap: break-word;
  height: 91%;
  border: 1px solid #ccc; /* 테두리 */
}

/* 메시지 설정 */
.messages {
  flex-grow: 1;
  overflow-y: scroll;
}

/* 입력 컨테이너 설정 */
.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
}

/* 입력 필드 설정 */
.input-container input {
  flex-grow: 1;
  margin-right: 10px;
}

/* 미디어 쿼리 - 가로 모드 설정 */
/*모바일, pc 의 경우를 나누기 위해 추가*/
@media (orientation: landscape) {
  .box {
    flex-direction: row;
  }
}

/* 미디어 쿼리 - 가로 모드 설정 */
/*모바일, pc 의 경우를 나누기 위해 추가*/
@media (orientation: portrait) {
  .box {
    flex-direction: column;
  }
}
